import { FC } from 'react';
import { t } from 'i18next';
import { ClientFormUserRole } from '../../models/ClientFormUserRoles';
import DropdownSelect from '../shared/form-control/DropdownSelect';
import { ProfileImageStack, ImageSize } from './ProfileImageStack';
import { ClientFormUser } from '../../models/ClientFormUser';
import { Option } from '../Option';
import XIcon from '../shared/icon/XIcon';

type UserAccessRowProps = {
  user: ClientFormUser;
  onRoleChange: (user: ClientFormUser, role: (typeof ClientFormUserRole)[keyof typeof ClientFormUserRole]) => void;
  onUserRemove: (user: ClientFormUser) => void;
  rolesOptions: Option<string, string | number>[];
  roleOptionsFiltered: Option<string, string | number>[];
};

const UserAccessRow: FC<UserAccessRowProps> = (props) => {
  const { user, onRoleChange, onUserRemove, rolesOptions, roleOptionsFiltered } = props;
  const findRole = (value: number) => {
    const role = rolesOptions.find((role) => role.value === value);
    if (!role) {
      return undefined;
    }

    return {
      id: role.id,
      value: role.value,
      text: role.text,
    };
  };

  return (
    <div data-cy={`form-user-${user.id}`} className="flex items-center py-2">
      <ProfileImageStack users={[user]} key={user.id} size={ImageSize.S} />
      <div className="mx-4 font-medium">{user.firstName && user.lastName ? `${user.firstName} ${user.lastName}` : user.email}</div>
      <div className="ml-auto">
        <div className="flex w-56 items-center">
          <DropdownSelect
            className="w-full min-w-40"
            data-cy={`form-user-${user.id}-role-options`}
            value={findRole(user.role)}
            onChange={(data) => onRoleChange(user, data.value as (typeof ClientFormUserRole)[keyof typeof ClientFormUserRole])}
            options={roleOptionsFiltered.filter((x) => x.value !== -1)}
            placeholder={t('common:permissions-modal.role-placeholder')}
            aria-label={t('common:permissions-modal.role-placeholder')}
            disabled={roleOptionsFiltered.length == 1}
          ></DropdownSelect>
          {<XIcon onClick={() => onUserRemove(user)} className="ml-2 h-6 w-6 flex-shrink-0" />}
        </div>
      </div>
    </div>
  );
};

export default UserAccessRow;
